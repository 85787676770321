import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { links } from 'config';
import moment from 'moment';

import { ReactComponent as Figure } from 'assets/img/figure.svg';
import videoBackground from 'assets/video/home-page-background.mp4';
import background from 'assets/img/background.jpg';
import logo from 'assets/img/logo.svg';

const Page = observer(() => {
  const [isProgressFinished, setIsProgressFinished] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
  const [isAssetsLoaded, setIsAssetsLoaded] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const [percentValue, setPercentValue] = useState(0);
  const [dateValue, setDateValue] = useState(new Date('2024-05-01'));

  const progressStart = new Date('2024-05-01').getTime();
  const progressEnd = new Date('2024-10-15').getTime();

  // const progress = ((Date.now() - progressStart) / (progressEnd - progressStart)).toFixed(2);
  const progress = 0.99;

  const progressAnimationDuration = 2000;
  const videos = [1, 2];

  const onTimeUpdate = (event) => {
    if (!isVideoLoaded) {
      onCanPlay();
    }

    const index = Number(event.target.dataset.index);

    if (index !== currentVideoIndex) return;
    if (event.target.currentTime < 16) return;
    if (document.querySelector('.home-page__background-video.fading')) return;

    const nextIndex = index === 1 ? 2 : 1;
    const nextVideo = document.querySelector(`.home-page__background-video--${nextIndex}`);
    nextVideo.currentTime = 0;

    event.target.classList.remove('active');
    nextVideo.classList.add('fading');
    setTimeout(() => {
      nextVideo.classList.remove('fading');
      nextVideo.classList.add('active');
    }, 1000);

    setCurrentVideoIndex(nextIndex);
  };

  const onCanPlay = () => {
    document.querySelectorAll('.home-page__background-video').forEach((video) => {
      video.classList.add('loaded');
      video.currentTime = 0;

      if (Number(video.dataset.index) !== 1) return;

      video.classList.add('active');
    });

    setIsVideoLoaded(true);
  };

  useEffect(() => {
    const fontPromise = new Promise((resolve) => {
      import('@fontsource/inter/files/inter-latin-400-normal.woff').then(resolve);
    });

    const previewPromise = new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.src = background;
    });

    Promise.all([fontPromise, previewPromise]).then(() => setIsAssetsLoaded(true));
  }, []);

  useEffect(() => {
    if (!isAssetsLoaded) return;

    const figureRect = document.querySelector('.home-page__progress-figure rect');
    const digits = document.querySelector('.home-page__progress-digits');
    const hr = document.querySelector('.home-page__progress-hr');

    const progressInPercents = `${progress * 100}%`;
    figureRect.style.width = progressInPercents;
    digits.style.left = progressInPercents;
    hr.style.left = progressInPercents;

    setTimeout(() => setIsProgressFinished(true), progressAnimationDuration);
  }, [isAssetsLoaded]);

  useEffect(() => {
    if (percentValue < progress * 100 && Number(progress) !== 0) {
      const delay = progressAnimationDuration / (progress * 100);
      setTimeout(() => {
        setPercentValue(percentValue + 1);
      }, delay);
    }
  }, [percentValue]);

  useEffect(() => {
    if (dateValue.getDate() !== new Date().getDate()) {
      const delay = progressAnimationDuration / new Date().getDate();
      setTimeout(() => {
        setDateValue(new Date(dateValue.setDate(dateValue.getDate() + 1)));
      }, delay);
    }
  }, [dateValue]);

  return (
    <div
      className={`home-page ${isAssetsLoaded ? 'home-page--assets-loaded' : ''} ${isVideoLoaded && isProgressFinished ? 'home-page--loaded' : ''}`}
    >
      <div className="home-page__background">
        <img className="home-page__background-img" src={background} alt="background" />
        {videos.map((index) => (
          <video
            className={`home-page__background-video home-page__background-video--${index}`}
            onTimeUpdate={onTimeUpdate}
            src={videoBackground}
            data-index={index}
            autoPlay={true}
            muted="muted"
            playsInline
            loop={true}
            key={index}
          />
        ))}

        <div className="home-page__background-blackout" />
      </div>

      <main className="home-page__content">
        <img className="home-page__logo" src={logo} alt="logo" />
        <div className="home-page__progress">
          <div className="home-page__progress-digits">
            <span className="home-page__progress-digits-percent">{percentValue}%</span>
            <span className="home-page__progress-digits-date">
              {moment(dateValue).format(`DD.MM.YY`)}
            </span>
          </div>
          <hr className="home-page__progress-hr" />
          <Figure className="home-page__progress-figure" />
        </div>
        <p className="home-page__disclaimer">OUR NEW Website Is COMING SOON</p>
        <div className="home-page__contacts">
          <a
            className="home-page__contacts-whatsapp"
            href={`https://wa.me/${links.trimTel()}`}
            rel="noreferrer"
            target="_blank"
          >
            <span>WhatsApp</span>
          </a>
          <a
            className="home-page__contacts-phone"
            href={`tel: ${links.trimTel()}`}
            rel="noreferrer"
            target="_blank"
          >
            <span>{links.tel}</span>
          </a>
        </div>
        <div className="home-page__location">
          <a href={links.map} target="_blank" rel="noreferrer">
            <div className="home-page__location-link">Location</div>
            <p className="home-page__location-info">
              <span>UAE - DUBAI - DUBAI INTERNET CITY</span>
              <span>SHEIKH ZAYED RD EMAAR BUSINESS PARK BUILDING 4 - OFFICE 109</span>
            </p>
          </a>
        </div>
        <p className="home-page__rights-desktop">2024 © ZILA GREEN REAL ESTATE LLC</p>
      </main>
      <div className="home-page__rights-overlay">
        <p className="home-page__rights">2024 © ZILA GREEN REAL ESTATE LLC</p>
      </div>
    </div>
  );
});

export default Page;
